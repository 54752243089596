var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-table",
    {
      attrs: {
        size: _vm.size,
        columns: _vm.columns,
        "data-source": _vm.dataSource,
        pagination: _vm.defaultPagination,
        paginationcustom: _vm.paginationcustom,
        "row-selection":
          _vm.selectedRowKeys && _vm.onSelectChange && _vm.getCheckboxProps
            ? {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                type: _vm.checkboxOrRadio ? _vm.checkboxOrRadio : "checkbox",
                getCheckboxProps: _vm.getCheckboxProps
                  ? _vm.getCheckboxProps
                  : null
              }
            : _vm.selectedRowKeys && _vm.onSelectChange
            ? {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                type: _vm.checkboxOrRadio ? _vm.checkboxOrRadio : "checkbox"
              }
            : null,
        bordered: _vm.bordered,
        scroll: _vm.scroll ? _vm.scroll : { x: "max-content", y: 600 },
        loading: _vm.loading ? _vm.loading : false,
        customRow: _vm.customRow,
        rowKey: _vm.rowKey
      },
      on: { change: _vm.handleTableChange },
      scopedSlots: _vm._u(
        [
          {
            key: _vm.expandedRowRender ? "expandedRowRender" : null,
            fn: function(record) {
              return _vm.expandedRowRender
                ? [_vm._t("custom-expandedRowRender", null, { record: record })]
                : undefined
            }
          },
          {
            key: "isRenderTag",
            fn: function(text) {
              return [
                text
                  ? _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.$sanitize(text)) }
                    })
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          {
            key: "isCurrency",
            fn: function(text) {
              return [
                text !== null
                  ? _c("div", [_vm._v(_vm._s(_vm._f("currency")(text)))])
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          {
            key: "isDate",
            fn: function(text) {
              return [
                text
                  ? _c("div", [_vm._v(_vm._s(_vm._f("date")(text)))])
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          {
            key: "isQty",
            fn: function(text) {
              return [
                text
                  ? _c("div", [_vm._v(_vm._s(_vm._f("qty")(text)))])
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          _vm._l(_vm.responsiveColInput, function(obColInput) {
            return {
              key: obColInput.name,
              fn: function(text, record) {
                return [
                  _vm.responsiveColInput && _vm.responsiveColInput.length > 0
                    ? _c("a-input", {
                        key: obColInput.name,
                        style:
                          obColInput && obColInput.style
                            ? "" + obColInput.style
                            : null,
                        attrs: {
                          value: text ? text : null,
                          placeholder:
                            obColInput && obColInput.placeholder
                              ? "" + obColInput.placeholder
                              : null,
                          disabled:
                            record.disableByRow &&
                            record.disableByRow.includes(obColInput.name)
                              ? true
                              : record.disabledInput
                              ? record.disabledInput
                              : obColInput.disabled
                              ? obColInput.disabled
                              : false
                        },
                        on: {
                          change: function(e) {
                            return _vm.handleInput(
                              e.target.value,
                              record.key,
                              obColInput,
                              obColInput.name,
                              "onChange"
                            )
                          },
                          blur: function(e) {
                            return _vm.handleInput(
                              e.target.value,
                              record.key,
                              obColInput,
                              obColInput.name,
                              "onBlur"
                            )
                          },
                          focus: function(e) {
                            return _vm.handleInput(
                              e.target.value,
                              record.key,
                              obColInput,
                              obColInput.name,
                              "onFocus"
                            )
                          },
                          keypress: function($event) {
                            return _vm.onkeypress($event, obColInput)
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.responsiveColTextArea, function(objColTextArea) {
            return {
              key: objColTextArea.name,
              fn: function(text, record) {
                return [
                  _vm.responsiveColTextArea &&
                  _vm.responsiveColTextArea.length > 0
                    ? _c("a-textarea", {
                        key: objColTextArea.name,
                        style:
                          objColTextArea && objColTextArea.style
                            ? "" + objColTextArea.style
                            : null,
                        attrs: {
                          value: text ? text : null,
                          placeholder:
                            objColTextArea && objColTextArea.placeholder
                              ? "" + objColTextArea.placeholder
                              : null,
                          "auto-size": { minRows: 2, maxRows: 6 },
                          disabled:
                            record.disableByRow &&
                            record.disableByRow.includes(objColTextArea.name)
                              ? true
                              : record.disabledInput
                              ? record.disabledInput
                              : objColTextArea.disabled
                              ? objColTextArea.disabled
                              : false
                        },
                        on: {
                          change: function(e) {
                            return _vm.handleInput(
                              e.target.value,
                              record.key,
                              objColTextArea,
                              objColTextArea.name,
                              "onChange"
                            )
                          },
                          blur: function(e) {
                            return _vm.handleInput(
                              e.target.value,
                              record.key,
                              objColTextArea,
                              objColTextArea.name,
                              "onBlur"
                            )
                          },
                          focus: function(e) {
                            return _vm.handleInput(
                              e.target.value,
                              record.key,
                              objColTextArea,
                              objColTextArea.name,
                              "onFocus"
                            )
                          },
                          keypress: function($event) {
                            return _vm.onkeypress($event, objColTextArea)
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          {
            key: "buttonAdd",
            fn: function(text, record) {
              return [
                _c(
                  "a-row",
                  {
                    attrs: {
                      type: "flex",
                      justify: "space-around",
                      align: "middle",
                      gutter: [16, 16]
                    }
                  },
                  [
                    text
                      ? _c("a-col", { attrs: { span: 12 } }, [
                          _c("label", [_vm._v(_vm._s(text) + " ... ")])
                        ])
                      : _vm._e(),
                    _c(
                      "a-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "a-dropdown",
                          {
                            attrs: {
                              trigger: ["click"],
                              placement: "bottomCenter"
                            }
                          },
                          [
                            _c("a-button", {
                              attrs: { icon: "bars" },
                              on: {
                                click: function(e) {
                                  return e.preventDefault()
                                }
                              }
                            }),
                            _c(
                              "a-menu",
                              {
                                staticClass: "mt-2",
                                attrs: { slot: "overlay" },
                                slot: "overlay"
                              },
                              [
                                [
                                  _c(
                                    "a-menu-item",
                                    {
                                      attrs: { title: "View/Add Data" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onAddData(record, text)
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: {
                                          "font-style": "14px",
                                          "margin-right": "7px",
                                          "vertical-align": "0"
                                        },
                                        attrs: { type: "save" }
                                      }),
                                      _vm._v("View/Add Data ")
                                    ],
                                    1
                                  )
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          _vm._l(_vm.responsiveButtonAdd, function(buttonColumn) {
            return {
              key: buttonColumn,
              fn: function(text, record) {
                return [
                  _c(
                    "a-row",
                    {
                      key: buttonColumn,
                      attrs: {
                        gutter: [16, 16],
                        type: "flex",
                        align: "middle",
                        justify: "space-around"
                      }
                    },
                    [
                      text
                        ? _c("a-col", { attrs: { span: 12 } }, [
                            _c("label", [_vm._v(_vm._s(text) + " ... ")])
                          ])
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-dropdown",
                            {
                              attrs: {
                                trigger: ["click"],
                                placement: "bottomCenter"
                              }
                            },
                            [
                              _c("a-button", {
                                attrs: { icon: "bars" },
                                on: {
                                  click: function(e) {
                                    return e.preventDefault()
                                  }
                                }
                              }),
                              _c(
                                "a-menu",
                                {
                                  staticClass: "mt-2",
                                  attrs: { slot: "overlay" },
                                  slot: "overlay"
                                },
                                [
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        attrs: { title: "View/Add Data" },
                                        on: {
                                          click: function($event) {
                                            return _vm.onAddDataResponsiveButtonAdd(
                                              record,
                                              text,
                                              buttonColumn
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "save" }
                                        }),
                                        _vm._v("View/Add Data ")
                                      ],
                                      1
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          }),
          {
            key: "buttonCustom",
            fn: function(text, record) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled:
                        record.disableByRow &&
                        record.disableByRow.includes(text)
                    },
                    on: {
                      click: function($event) {
                        return _vm.onCustomData(record, text)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(text) + " ")]
                )
              ]
            }
          },
          {
            key: "buttonView",
            fn: function(text, record) {
              return [
                _c(
                  "a-row",
                  {
                    attrs: {
                      gutter: [16, 16],
                      type: "flex",
                      justify: "space-around",
                      align: "middle"
                    }
                  },
                  [
                    text
                      ? _c("a-col", { attrs: { span: 12 } }, [
                          _c("label", [_vm._v(_vm._s(text))])
                        ])
                      : _vm._e(),
                    _c(
                      "a-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "a-dropdown",
                          {
                            attrs: {
                              trigger: ["click"],
                              placement: "bottomCenter"
                            }
                          },
                          [
                            _c("a-button", {
                              attrs: { icon: "bars" },
                              on: {
                                click: function(e) {
                                  return e.preventDefault()
                                }
                              }
                            }),
                            _c(
                              "a-menu",
                              {
                                staticClass: "mt-2",
                                attrs: { slot: "overlay" },
                                slot: "overlay"
                              },
                              [
                                [
                                  _c(
                                    "a-menu-item",
                                    {
                                      attrs: { title: "View Data" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onViewData(record, text)
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: {
                                          "font-style": "14px",
                                          "margin-right": "7px",
                                          "vertical-align": "0"
                                        },
                                        attrs: { type: "save" }
                                      }),
                                      _vm._v("View Data ")
                                    ],
                                    1
                                  )
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          _vm._l(_vm.responsiveColInputNumber, function(obColInputNumber) {
            return {
              key: obColInputNumber.name,
              fn: function(text, record) {
                return [
                  _vm.responsiveColInputNumber &&
                  _vm.responsiveColInputNumber.length > 0
                    ? _c("a-input-number", {
                        key: obColInputNumber.name,
                        style:
                          obColInputNumber && obColInputNumber.style
                            ? "" + obColInputNumber.style
                            : null,
                        attrs: {
                          value: record[obColInputNumber.name],
                          parser: _vm.reverseFormatNumber,
                          formatter: _vm.formatterNumber,
                          placeholder:
                            obColInputNumber && obColInputNumber.placeholder
                              ? "" + obColInputNumber.placeholder
                              : null,
                          min: obColInputNumber.min || 0,
                          precision:
                            obColInputNumber.precision ||
                            _vm.storeBaseDecimalPlace,
                          disabled:
                            record.disableByRow &&
                            record.disableByRow.includes(obColInputNumber.name)
                              ? true
                              : record.disabledInputNumber
                              ? record.disabledInputNumber
                              : obColInputNumber.disabled
                              ? obColInputNumber.disabled
                              : false
                        },
                        on: {
                          change: function(e) {
                            return _vm.handleInputNumber(
                              e,
                              record.key,
                              obColInputNumber,
                              obColInputNumber.name
                            )
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.responsiveColDate, function(obColDate) {
            return {
              key: obColDate.name,
              fn: function(text, record) {
                return [
                  _vm.responsiveColDate && _vm.responsiveColDate.length > 0
                    ? _c("a-switch", {
                        key: obColDate.name,
                        style:
                          obColDate && obColDate.style
                            ? "" + obColDate.style
                            : null,
                        attrs: {
                          placeholder:
                            obColDate && obColDate.placeholder
                              ? "" + obColDate.placeholder
                              : null,
                          disabled: record.disabledDate
                            ? record.disabledDate
                            : false,
                          defaultValue: text
                        },
                        on: {
                          change: function(date, dateString) {
                            return _vm.handleDate(
                              date,
                              record.key,
                              obColDate,
                              dateString
                            )
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.responsiveColDateMonth, function(obColDateMonth) {
            return {
              key: obColDateMonth.name,
              fn: function(text, record) {
                return [
                  _vm.responsiveColDateMonth &&
                  _vm.responsiveColDateMonth.length > 0
                    ? _c("a-date-picker", {
                        key: obColDateMonth.name,
                        style:
                          obColDateMonth && obColDateMonth.style
                            ? "" + obColDateMonth.style
                            : null,
                        attrs: {
                          placeholder:
                            obColDateMonth && obColDateMonth.placeholder
                              ? "" + obColDateMonth.placeholder
                              : null,
                          disabled:
                            record.disableByRow &&
                            record.disableByRow.includes(obColDateMonth.name)
                              ? true
                              : obColDateMonth.disabled
                              ? _vm.objColCheckBox.disabled
                              : record.disabledDateMonth
                              ? record.disabledDateMonth
                              : text === undefined
                              ? true
                              : false,
                          format: obColDateMonth.format
                            ? obColDateMonth.format
                            : _vm.DEFAULT_DATE_FORMAT,
                          "show-time": obColDateMonth.showTime
                        },
                        on: {
                          change: function(date, dateString) {
                            return _vm.handleDateMonth(
                              date,
                              record.key,
                              obColDateMonth.name,
                              dateString
                            )
                          }
                        },
                        model: {
                          value: record[obColDateMonth.name],
                          callback: function($$v) {
                            _vm.$set(record, obColDateMonth.name, $$v)
                          },
                          expression: "record[obColDateMonth.name]"
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.responsiveColRangePicker, function(obColRangePicker) {
            return {
              key: obColRangePicker.name,
              fn: function(text, record) {
                return [
                  _vm.responsiveColRangePicker &&
                  _vm.responsiveColRangePicker.length > 0
                    ? _c("a-range-picker", {
                        key: obColRangePicker.name,
                        style:
                          obColRangePicker && obColRangePicker.style
                            ? "" + obColRangePicker.style
                            : null,
                        attrs: {
                          disabled: record.disabledDate
                            ? record.disabledDate
                            : false,
                          defaultValue: text,
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        on: {
                          change: function(date, dateString) {
                            return _vm.handleDatePicker(
                              date,
                              record.key,
                              obColRangePicker,
                              dateString
                            )
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.responsiveColSwitch, function(objColSwitch) {
            return {
              key: objColSwitch.name,
              fn: function(text, record) {
                return [
                  _vm.responsiveColSwitch && _vm.responsiveColSwitch.length > 0
                    ? _c("a-switch", {
                        key: objColSwitch.name,
                        style:
                          objColSwitch && objColSwitch.style
                            ? "" + objColSwitch.style
                            : null,
                        attrs: {
                          checked: record[objColSwitch.name],
                          placeholder:
                            objColSwitch && objColSwitch.placeholder
                              ? "" + objColSwitch.placeholder
                              : null,
                          disabled:
                            record.disableByRow && record.disableByRow.length
                              ? record.disableByRow.includes(objColSwitch.name)
                              : false
                        },
                        on: {
                          change: function(checked) {
                            return _vm.handleSwitch(
                              checked,
                              record.key,
                              objColSwitch.name,
                              record
                            )
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.responsiveColCheckBox, function(objColCheckBox) {
            return {
              key:
                "" +
                (objColCheckBox.name ? objColCheckBox.name : objColCheckBox),
              fn: function(text, record) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.log(objColCheckBox.name, text, record)) +
                      " "
                  ),
                  _vm.responsiveColCheckBox &&
                  _vm.responsiveColCheckBox.length > 0
                    ? _c("a-checkbox", {
                        key: objColCheckBox.name
                          ? objColCheckBox.name
                          : objColCheckBox,
                        attrs: {
                          checked: text ? true : false,
                          disabled:
                            record.disableByRow &&
                            record.disableByRow.includes(objColCheckBox.name)
                              ? true
                              : objColCheckBox.disabled
                              ? objColCheckBox.disabled
                              : record.disabledCheckBox
                              ? record.disabledCheckBox
                              : text === undefined
                              ? true
                              : false
                        },
                        on: {
                          change: function(e) {
                            return _vm.handleCheckBox(
                              e.target.checked,
                              record.key,
                              objColCheckBox.name
                                ? objColCheckBox.name
                                : objColCheckBox,
                              e.target.value
                            )
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.stateResponsiveColSelect, function(
            objColSelect,
            indexObjColSelect
          ) {
            return {
              key: objColSelect.name,
              fn: function(text, record) {
                return [
                  _vm.stateResponsiveColSelect &&
                  _vm.stateResponsiveColSelect.length > 0
                    ? _c(
                        "a-select",
                        {
                          key: "" + objColSelect.name + record.key,
                          style:
                            objColSelect && objColSelect.style
                              ? "" + objColSelect.style
                              : null,
                          attrs: {
                            placeholder:
                              objColSelect && objColSelect.placeholder
                                ? "" + objColSelect.placeholder
                                : null,
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option":
                              objColSelect.filterOption &&
                              objColSelect.filterOption === "remote"
                                ? false
                                : _vm.filterOption,
                            disabled:
                              record.disableByRow &&
                              record.disableByRow.includes(objColSelect.name)
                                ? true
                                : record.disabledSelect
                                ? record.disabledSelect
                                : objColSelect.disabled
                                ? objColSelect.disabled
                                : false,
                            value: text,
                            loading: objColSelect.loading
                              ? objColSelect.loading
                              : false,
                            allowClear: true,
                            dropdownMatchSelectWidth: false
                          },
                          on: {
                            change: function(value) {
                              return _vm.handleSelect(
                                value,
                                record.key,
                                objColSelect.name,
                                objColSelect.options
                              )
                            },
                            search: function(value) {
                              return _vm.handleSearchSelectTable(
                                value,
                                record.key,
                                objColSelect.name
                              )
                            }
                          }
                        },
                        _vm._l(
                          _vm.handleOptionSelect(
                            indexObjColSelect,
                            record,
                            objColSelect.name
                          ),
                          function(option, index) {
                            return _c(
                              "a-select-option",
                              {
                                key: index,
                                attrs: {
                                  value: option["" + objColSelect.value]
                                }
                              },
                              [
                                _c(
                                  "a-tooltip",
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(" " + _vm._s(option.name) + " ")
                                    ]),
                                    _vm._v(" " + _vm._s(option.name) + " ")
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          }),
          _vm._l(_vm.stateResponsiveColSelectAddNew, function(
            objColSelectAddNew,
            indexObjColSelectAddNew
          ) {
            return {
              key: objColSelectAddNew.name,
              fn: function(text, record) {
                return [
                  _vm.stateResponsiveColSelectAddNew &&
                  _vm.stateResponsiveColSelectAddNew.length > 0
                    ? _c(
                        "a-select",
                        {
                          key: "" + objColSelectAddNew.name + record.key,
                          style:
                            objColSelectAddNew && objColSelectAddNew.style
                              ? "" + objColSelectAddNew.style
                              : null,
                          attrs: {
                            placeholder:
                              objColSelectAddNew &&
                              objColSelectAddNew.placeholder
                                ? "" + objColSelectAddNew.placeholder
                                : null,
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            disabled:
                              record.disableByRow &&
                              record.disableByRow.includes(
                                objColSelectAddNew.name
                              )
                                ? true
                                : record.disabledSelect
                                ? record.disabledSelect
                                : objColSelectAddNew.disabled
                                ? objColSelectAddNew.disabled
                                : false,
                            value: text,
                            loading: objColSelectAddNew.loading
                              ? objColSelectAddNew.loading
                              : false,
                            allowClear: true
                          },
                          on: {
                            change: function(value) {
                              return _vm.handleSelect(
                                value,
                                record.key,
                                objColSelectAddNew.name,
                                objColSelectAddNew.options
                              )
                            },
                            search: function(value) {
                              return _vm.handleSearchSelectTable(
                                value,
                                record.key,
                                objColSelectAddNew.name
                              )
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "dropdownRender",
                                fn: function(menu) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c("v-nodes", {
                                        attrs: { vnodes: menu }
                                      }),
                                      _c("a-divider", {
                                        staticStyle: { margin: "4px 0" }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "8px",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            mousedown: function(e) {
                                              return e.preventDefault()
                                            },
                                            click: function($event) {
                                              return _vm.addNew(
                                                record.key,
                                                objColSelectAddNew.name
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "plus" }
                                          }),
                                          _vm._v(" Add New ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        _vm._l(
                          _vm.handleOptionSelectAddNew(
                            indexObjColSelectAddNew,
                            record,
                            objColSelectAddNew.name
                          ),
                          function(option, index) {
                            return _c(
                              "a-select-option",
                              {
                                key: index,
                                attrs: {
                                  value: option["" + objColSelectAddNew.value]
                                }
                              },
                              [
                                _c(
                                  "a-tooltip",
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(" " + _vm._s(option.name) + " ")
                                    ]),
                                    _vm._v(" " + _vm._s(option.name) + " ")
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          }),
          {
            key: "filterDropdown",
            fn: function(ref) {
              var setSelectedKeys = ref.setSelectedKeys
              var selectedKeys = ref.selectedKeys
              var confirm = ref.confirm
              var clearFilters = ref.clearFilters
              var column = ref.column
              return _c(
                "div",
                { staticStyle: { padding: "8px" } },
                [
                  _c("a-input", {
                    staticStyle: {
                      width: "188px",
                      "margin-bottom": "8px",
                      display: "block"
                    },
                    attrs: {
                      placeholder: "Search " + column.dataIndex,
                      value: selectedKeys[0]
                    },
                    on: {
                      change: function(e) {
                        return setSelectedKeys(
                          e.target.value ? [e.target.value] : []
                        )
                      },
                      pressEnter: function() {
                        return _vm.handleSearch(
                          selectedKeys,
                          confirm,
                          column.dataIndex
                        )
                      }
                    }
                  }),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px", "margin-right": "8px" },
                      attrs: { type: "primary", icon: "search", size: "small" },
                      on: {
                        click: function() {
                          return _vm.handleSearch(
                            selectedKeys,
                            confirm,
                            column.dataIndex
                          )
                        }
                      }
                    },
                    [_vm._v(" Search ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { size: "small" },
                      on: {
                        click: function() {
                          return _vm.handleReset(clearFilters)
                        }
                      }
                    },
                    [_vm._v(" Reset ")]
                  )
                ],
                1
              )
            }
          },
          {
            key: "filterIcon",
            fn: function(filtered) {
              return _c("a-icon", {
                style: { color: filtered ? "#108ee9" : undefined },
                attrs: { type: "search" }
              })
            }
          },
          {
            key: "customRender",
            fn: function(text, record, index, column) {
              return [
                _vm.searchText && _vm.searchedColumn === column.dataIndex
                  ? _c(
                      "span",
                      [
                        _vm._l(
                          text
                            .toString()
                            .split(
                              new RegExp(
                                "(?<=" +
                                  _vm.searchText +
                                  ")|(?=" +
                                  _vm.searchText +
                                  ")",
                                "i"
                              )
                            ),
                          function(fragment, i) {
                            return [
                              fragment.toLowerCase() ===
                              _vm.searchText.toLowerCase()
                                ? _c(
                                    "mark",
                                    { key: i, staticClass: "highlight" },
                                    [_vm._v(_vm._s(fragment))]
                                  )
                                : [_vm._v(_vm._s(fragment))]
                            ]
                          }
                        )
                      ],
                      2
                    )
                  : [_vm._v(" " + _vm._s(text) + " ")]
              ]
            }
          },
          {
            key: "qrCode",
            fn: function(text, record) {
              return [
                text
                  ? _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.handleClickQrCode(text, record)
                          }
                        }
                      },
                      [
                        _c("QrCode", {
                          attrs: {
                            value: text,
                            quality: 1,
                            scale: 2,
                            size: 100,
                            whiteMargin: false
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          {
            key: "isEnable",
            fn: function(text) {
              return [
                text === true || text == true || text
                  ? _c(
                      "div",
                      [
                        _c("a-icon", {
                          staticStyle: { color: "#52c41a" },
                          attrs: { type: "check" }
                        })
                      ],
                      1
                    )
                  : text === false
                  ? _c(
                      "div",
                      [
                        _c("a-icon", {
                          staticStyle: { color: "#f5222d" },
                          attrs: { type: "close" }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          {
            key: "isActive",
            fn: function(text) {
              return [
                text === true || text == true || text
                  ? _c("div", [
                      _c(
                        "p",
                        { staticStyle: { color: "#52c41a", margin: "0" } },
                        [_vm._v("Active")]
                      )
                    ])
                  : text === false
                  ? _c("div", [
                      _c(
                        "p",
                        { staticStyle: { color: "#f5222d", margin: "0" } },
                        [_vm._v("Inactive")]
                      )
                    ])
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          {
            key: "isNull",
            fn: function(text) {
              return [
                text === 0 || text === "0" || text
                  ? _c("div", [_vm._v(" " + _vm._s(text) + " ")])
                  : _c("div", [_vm._v("-")])
              ]
            }
          },
          {
            key: "clickColumn",
            fn: function(text, record) {
              return [
                text
                  ? _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer", color: "blue" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleClickColumn(record, text)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(text) + " ")]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "clickColumnArrayMode",
            fn: function(text, record) {
              return [
                text && Array.isArray(text) && text.length > 0
                  ? _c(
                      "div",
                      _vm._l(text, function(data, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "blue",
                              display: "block"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.handleClickColumnArray(
                                  record,
                                  data,
                                  text
                                )
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(data) + " ")]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "isClick",
            fn: function(text, record) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.isClick(text, record)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(text) + " ")]
                )
              ]
            }
          },
          {
            key: "customUnitCodeParentAndChild",
            fn: function(text, record) {
              return [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        record.unitCodeParent ? record.unitCodeParent : "-"
                      ) +
                      _vm._s(
                        record.unitCodeChildList &&
                          record.unitCodeChildList.length > 0
                          ? "; " + record.unitCodeChildList.join(",")
                          : null
                      ) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "operation",
            fn: function(text, record) {
              return [
                _c(
                  "a-row",
                  [
                    _c(
                      "a-dropdown",
                      {
                        attrs: { trigger: ["click"], placement: "bottomCenter" }
                      },
                      [
                        _c("a-button", {
                          attrs: { icon: "bars" },
                          on: {
                            click: function(e) {
                              return e.preventDefault()
                            }
                          }
                        }),
                        _c(
                          "a-menu",
                          {
                            staticClass: "mt-2",
                            attrs: { slot: "overlay" },
                            slot: "overlay"
                          },
                          [
                            _vm._l(_vm.resultbutton, function(item) {
                              return [
                                _vm.dataSource.length && item === "delete"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        on: {
                                          click: function($event) {
                                            return _vm.onDelete(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "delete" }
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_delete")) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item === "detail"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        attrs: { title: _vm.$t("lbl_details") },
                                        on: {
                                          click: function($event) {
                                            return _vm.onDetail(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "eye" }
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_details")) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item === "update"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        attrs: { title: _vm.$t("lbl_update") },
                                        on: {
                                          click: function($event) {
                                            return _vm.onEdit(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "edit" }
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_update")) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item === "view"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        attrs: { title: _vm.$t("lbl_view") },
                                        on: {
                                          click: function($event) {
                                            return _vm.onView(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "eye" }
                                        }),
                                        _vm._v(_vm._s(_vm.$t("lbl_view")) + " ")
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item === "save"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        attrs: { title: _vm.$t("lbl_save") },
                                        on: {
                                          click: function($event) {
                                            return _vm.onSave(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "save" }
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("lbl_save")) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item === "download"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        attrs: {
                                          title: _vm.$t("lbl_download")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onDownload(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "download" }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_download")) +
                                            " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item === "return"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        attrs: {
                                          title:
                                            _vm.labelReturn ||
                                            _vm.$t("lbl_return_short")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onReturn(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "issues-close" }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.labelReturn ||
                                                _vm.$t("lbl_return_short")
                                            ) +
                                            " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item === "print"
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: item,
                                        attrs: { title: _vm.$t("lbl_print") },
                                        on: {
                                          click: function($event) {
                                            return _vm.onPrint(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: "printer" }
                                        }),
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_print")) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }),
                            _vm._l(_vm.responsiveMenu, function(item, index) {
                              return [
                                _vm.responsiveMenu.length > 0
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "resultButton" + index,
                                        attrs: { title: item.title },
                                        on: {
                                          click: function($event) {
                                            return item.triggerMenu(record)
                                          }
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            "font-style": "14px",
                                            "margin-right": "7px",
                                            "vertical-align": "0"
                                          },
                                          attrs: { type: item.icon }
                                        }),
                                        _vm._v(_vm._s(item.title) + " ")
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "viewMore",
            fn: function(text, record, index) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function($event) {
                        return _vm.viewMore(record, index)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("lbl_more")) + " ")]
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [
      _vm.footerRight || _vm.footerCreateInternalContract
        ? _c(
            "template",
            {
              attrs: {
                "slot-scope":
                  _vm.footerRight || _vm.footerCreateInternalContract
                    ? "currentPageData"
                    : ""
              },
              slot: "footer"
            },
            [
              _c(
                "div",
                [
                  _vm.footerRight
                    ? _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end"
                              },
                              attrs: { span: 24 }
                            },
                            [
                              _c("a-space", { staticClass: "mr-3" }, [
                                _vm._v(
                                  " Debit : " +
                                    _vm._s(_vm.footerRight.debit) +
                                    " "
                                )
                              ]),
                              _c("a-space", [
                                _vm._v(
                                  " Credit : " +
                                    _vm._s(_vm.footerRight.credit) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.footerCreateInternalContract
                    ? _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end"
                              },
                              attrs: { span: 24 }
                            },
                            [
                              _c(
                                "a-space",
                                [
                                  _vm._v(" Total : "),
                                  _c("a-input", {
                                    attrs: {
                                      value:
                                        _vm.footerCreateInternalContract.total,
                                      disabled: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _c("template", { slot: "footer" }, [_vm._t("custom-footer")], 2)
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }