




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { PropsModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Dropdown } from "ant-design-vue";
import Vue from "vue";
import { mapState } from "vuex";
Vue.use(Dropdown);
export default Vue.extend({
  components: {
    VNodes: {
      functional: true,
      render: (_h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      formatterNumber,
      reverseFormatNumber,
      responsiveColCheckBox: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColInput: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColTextArea: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColInputNumber: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColDate: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColSwitch: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColDateMonth: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColRangePicker: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColSelect: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveColSelectAddNew: [], // nama dataIndex kolomnya, masukkan ke dalam props ini
      responsiveButtonAdd: [], // berisi string nama column

      responsiveMenu: [],
      resultbutton: "",
      // buttonOneOperation: '',
      searchText: "",
    };
  },
  mounted() {
    this.responsiveColCheckBox = this.columns.find(
      data => data.responsiveColCheckBox
    )?.responsiveColCheckBox;
    this.responsiveColInput = this.columns.find(
      data => data.responsiveColInput
    )?.responsiveColInput;
    this.responsiveColTextArea = this.columns.find(
      data => data.responsiveColTextArea
    )?.responsiveColTextArea;
    this.responsiveButtonAdd = this.columns.find(
      data => data.responsiveButtonAdd
    )?.responsiveButtonAdd;
    this.responsiveColInputNumber = this.columns.find(
      data => data.responsiveColInputNumber
    )?.responsiveColInputNumber;
    this.responsiveColDate = this.columns.find(
      data => data.responsiveColDate
    )?.responsiveColDate;
    this.responsiveColSwitch = this.columns.find(
      data => data.responsiveColSwitch
    )?.responsiveColSwitch;
    this.responsiveColDateMonth = this.columns.find(
      data => data.responsiveColDateMonth
    )?.responsiveColDateMonth;
    this.responsiveColRangePicker = this.columns.find(
      data => data.responsiveColRangePicker
    )?.responsiveColRangePicker;
    this.responsiveColSelect = this.columns.find(
      data => data.responsiveColSelect
    )?.responsiveColSelect;
    this.responsiveColSelectAddNew = this.columns.find(
      data => data.responsiveColSelectAddNew
    )?.responsiveColSelectAddNew;
    if (this.columns.find(data => data.dataIndex === "operation")) {
      this.responsiveMenu = this.columns.find(
        data => data.dataIndex === "operation"
      ).responsiveMenu;
      this.resultbutton = this.columns.find(
        data => data.dataIndex === "operation"
      ).button;
    }
    if (!this.responsiveColCheckBox) this.responsiveColCheckBox = [];
    if (!this.responsiveColInputNumber) this.responsiveColInputNumber = [];
    if (!this.responsiveColDate) this.responsiveColDate = [];
    if (!this.responsiveColSwitch) this.responsiveColSwitch = [];
    if (!this.responsiveColDateMonth) this.responsiveColDateMonth = [];
    if (!this.responsiveColRangePicker) this.responsiveColRangePicker = [];
    if (!this.responsiveColSelect) this.responsiveColSelect = [];
    if (!this.responsiveColSelectAddNew) this.responsiveColSelectAddNew = [];
    if (!this.responsiveColInput) this.responsiveColInput = [];
    if (!this.responsiveColTextArea) this.responsiveColTextArea = [];
    if (!this.responsiveButtonAdd) this.responsiveButtonAdd = [];
  },
  props: [
    "checkboxOrRadio",
    "handleDatePicker",
    "dataSource",
    "columns",
    "defaultPagination",
    "bordered",
    "scroll",
    "selectedRowKeys",
    "onSelectChange",
    "handleInput",
    "handleInputNumber",
    "handleDate",
    "handleSwitch",
    "handleDateMonth",
    "handleCheckBox",
    "handleSelect",
    "handleSearchSelectTable",
    "paginationcustom",
    "loading",
    "isClick",
    "footerRight",
    "footerCreateInternalContract",
    "expandedRowRender",
    "getCheckboxProps",
    "rowKey",
    "addNew",
    "labelReturn",
    "size",
  ],
  methods: {
    log(..._args) {
      return;
    },
    onkeypress(evt, obColInput) {
      const charCode = evt.keyCode;
      const column = [
        "rate",
        "qtyTotal",
        "qtyPhysical",
        "qtyDifference",
        "qtyAdjustment",
        "fromQty",
        "toQty",
        "price",
        "conversionRate",
        "debit",
        "credit",
        "unitPrice",
        "qty",
        "taxInvoiceSerialNumberFrom",
        "taxInvoiceSerialNumberTo",
        "rentPrice",
      ]; // define which column need to add validation rules
      if (obColInput.name) {
        // only allow number and decimal
        if (
          column.includes(obColInput.name) &&
          charCode != 46 &&
          charCode != 45 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          evt.preventDefault();
        }
      }
    },
    handleClickQrCode(value, record) {
      let params = {
        value,
        record,
      };
      this.$emit("on-clickQr", params);
    },
    customRow(record) {
      return {
        on: {
          click: e => {
            if (!e.target.dataset.DOMStringMap)
              this.$emit("on-clickrow", record);
          },
        },
      };
    },
    // handle search select
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // handle search setiap columns
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    onDelete(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$confirm({
        title: this.$t(`lbl_modal_delete_title_confirm`),
        okText: this.$t(`lbl_ok`).toString(),
        cancelText: this.$t(`lbl_cancel`).toString(),
        onOk: () => {
          this.$emit("on-delete", params);
        },
        onCancel() {
          return;
        },
      });
    },
    onDetail(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-detail", params);
    },
    onEdit(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-edit", params);
    },
    onView(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-view", params);
    },
    onAddData(record, value): void {
      const params = {
        data: record,
        index: this.idtable,
        value,
      };
      this.$emit("on-addData", params);
    },
    onAddDataResponsiveButtonAdd(record, value, columnName) {
      const params = {
        data: record,
        value,
        columnName,
      };
      this.$emit("on-addDataResponsive", params);
    },
    onCustomData(record, value): void {
      const params = {
        data: record,
        index: this.idtable,
        value,
      };
      this.$emit("on-customData", params);
    },
    onViewData(record, value): void {
      const params = {
        data: record,
        index: this.idtable,
        value,
      };
      this.$emit("on-viewData", params);
    },
    onSave(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-save", params);
    },
    onDownload(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-download", params);
    },
    onReturn(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-return", params);
    },
    onPrint(record): void {
      const params = {
        data: record,
        index: this.idtable,
      };
      this.$emit("on-print", params);
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        key => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    handleTableChange(pagination, filters, sorter): void {
      const params = {
        sort: sorter,
        index: this.idtable,
        pagination,
        filters,
      };
      this.$emit("on-tablechange", params);
    },
    handleOptionSelect(index, record, currentNameColumn): PropsModel[] {
      let tempOption = null;
      if (record.optionCustom) {
        record.optionCustom.forEach(dataLoop => {
          if (currentNameColumn === dataLoop.name) {
            tempOption = dataLoop.option;
          }
        });
      }

      if (tempOption) {
        return tempOption;
      } else {
        return this.responsiveColSelect[index]["options"];
      }
    },
    handleOptionSelectAddNew(index, record, currentNameColumn): PropsModel[] {
      let tempOption = null;
      if (record.optionCustom) {
        record.optionCustom.forEach(dataLoop => {
          if (currentNameColumn === dataLoop.name) {
            tempOption = dataLoop.option;
          }
        });
      }

      if (tempOption) {
        return tempOption;
      } else {
        return this.responsiveColSelectAddNew[index]["options"];
      }
    },
    handleClickColumn(record, value) {
      let objColumnNameValue;
      for (const key in record) {
        if (record[key] === value) {
          objColumnNameValue = {
            column: key,
            value: record[key],
          };
        }
      }
      this.$emit("on-columnClicked", record, objColumnNameValue);
    },
    handleClickColumnArray(record, data, value) {
      let objColumnNameValue;
      for (const key in record) {
        if (
          Array.isArray(record[key]) &&
          record[key].length === value.length &&
          record[key].every(function (dataEvery, index) {
            return dataEvery === value[index];
          })
        ) {
          objColumnNameValue = {
            column: key,
            value: data, // value change with data
            dataColumn: value,
          };
        }
      }
      this.$emit("on-columnClickedArray", record, objColumnNameValue);
    },
    viewMore(record: PropsModel, index: number): void {
      this.$emit("on-view-more", { record, index });
    },
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    // optionsSelect(index:number) {
    //   return this.responsiveColSelect[index].options;
    // },
    stateResponsiveColSelect() {
      return this.responsiveColSelect;
    },
    stateResponsiveColSelectAddNew() {
      return this.responsiveColSelectAddNew;
    },
  },
});
